
import React, { Component } from "react"
import { Box, Tab, Tabs } from "grommet"
import ReactMarkdown from "react-markdown"


class Output extends Component {

    constructor(props) {
      super(props)
      this.state = {
        index: 0,
        source: "",
      }
    }

    handleTabChange(index) {
      this.setState({index: index})
      if (index === 0) {
          fetch(`/assignments/${this.props.assignment.name}/readme.md`)
            .then(response => response.text())
            .then(text => this.setState({source: text})
            )
            .catch(err => console.log(err));
      }
      else {
          console.log(this.props.executorStdErr);
          this.setState({source: " " +
            "### Message:\n" +
            "```\n" + this.props.executorMessage + "\n" +
            "```\n\n" +
            "### StdOut:\n" +
            "```\n" + this.props.executorStdOut + "\n" +
            "```\n\n" + 
            "### StdErr:\n" +
            "```\n" + this.props.executorStdErr + "\n" +
            "```\n\n"})
      }
    }

    componentDidMount() {
      this.handleTabChange(0)
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.assignment.name !== this.props.assignment.name 
      ) {
        console.log("Reloading Readme")
        this.handleTabChange(0)
      }
      if (
        prevProps.executorMessage !== this.props.executorMessage
        || prevProps.executorStdOut !== this.props.executorStdOut
        || prevProps.executorStdErr !== this.props.executorStdErr
      ) {
        this.handleTabChange(1)
      }
    }


    render() {
      return (
        <Box flex align='start' justify='start'>
          <Box align="center" justify="center" background='header' width="100%">
            <Tabs activeIndex={this.state.index} onActive={i => this.handleTabChange(i)}>
              <Tab title="README"></Tab>
              <Tab title="Output"></Tab>
            </Tabs>
          </Box>
          <Box align="start" justify="start">
            <ReactMarkdown 
              source={this.state.source}
            />
          </Box>
        </Box>
      );
    }
}

export default Output;

import React, { Component } from "react"

import { Box, Tabs, Tab } from "grommet"
import AceEditor from "react-ace";

// import "ace-builds/src-min-noconflict/ext-searchbox";
// import "ace-builds/src-min-noconflict/ext-language_tools";
// import "ace-builds/src-noconflict/mode-jsx";

const themes = [
  "monokai",
  "github",
  "tomorrow",
  "kuroir",
  "twilight",
  "xcode",
  "textmate",
  "solarized_dark",
  "solarized_light",
  "terminal"
];

require('ace-builds/src-noconflict/mode-python');
require('ace-builds/src-noconflict/snippets/python');

themes.forEach(theme => require(`ace-builds/src-noconflict/theme-${theme}`));


class Editor extends Component {

    constructor(props) {
      super(props);
      this.state = {
        index: 0,
        code: "",
      }
    }

    getFilename(index) {
      return this.props.assignment.files[index]
    }

    isUserCode(filename) {
      return filename === "submission.py"
    }
    
    handleTyping(newVal) {
      if (this.isUserCode(this.getFilename(this.state.index))) {
        this.props.onUserCodeChange(newVal)
        this.setState({code: this.props.userCode})
      }
      else {
        this.setState({code:this.state.code})
      }
    }

    handleTabChange(newIndex) {
        this.setState({index:newIndex});
        if (this.isUserCode(this.getFilename(newIndex))) {
            console.log(`Displaying Usercode`)
            this.setState({code: this.props.userCode})
        }
        else {
            fetch(`/assignments/${this.props.assignment.name}/${this.getFilename(newIndex)}`)
              .then(response => response.text())
              .then(text => this.setState({code: text}))
        }
    }

    componentDidMount() {
      this.handleTabChange(0)
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.assignment.name !== this.props.assignment.name) {
        this.handleTabChange(0)
      }
      if (prevProps.userCode !== this.props.userCode) {
        if (this.isUserCode(this.getFilename(this.state.index))) {
          console.log(`Loaded new usercode from above`)
          this.setState({code: this.props.userCode})
        }
      }
    }

    render() {
        let tabs = []
        this.props.assignment.files.forEach((filename) => {
            tabs.push(
              <Tab title={filename}>

              </Tab>
            )
        })

        return (
          <Box flex align='center' justify='center' background='header'>
            <Tabs activeIndex={this.state.index} onActive={i => this.handleTabChange(i)}>
              {tabs}
            </Tabs>
            <AceEditor
              mode="python"
              theme="github"
              height="100%"
              width="100%"
              onChange={(newVal) => this.handleTyping(newVal)}
              value={this.state.code}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true }}
            /> 
          </Box>
        );
    }
}

export default Editor;
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:82f5fcd8-0970-40eb-a63d-89f99b996c49",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6jcbjgbqd",
    "aws_user_pools_web_client_id": "7m54lvfetk65gqq39dbgor7nc",
    "oauth": {
        "domain": "metaprogramming34834532-34834532-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.pythonmeta.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.pythonmeta.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "ExecutorApi",
            "endpoint": "https://klx8dd2jb0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://666csgg6e5dppemyyme57fp2yy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;

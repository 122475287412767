import React, { Component } from "react";
import { Box, Grommet } from 'grommet';
import AppBar from "./components/AppBar";
import assignments from "./assignment-manifest";
import Output from "./components/Output";
import SideBar from "./components/SideBar";
import Editor from "./components/Editor";
import Amplify, { Auth, Storage, API, graphqlOperation } from 'aws-amplify';
import { createPuzzleSave } from './graphql/mutations';
import { getPuzzleSave } from './graphql/queries';
import awsExports from "./aws-exports";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

// ------ Additional Amplify Config ----- //
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
var newExports = awsExports;
const oauth = {
  domain: "metaprogramming34834532-34834532-dev.auth.us-east-1.amazoncognito.com",
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: 'http://localhost:3000/',
  redirectSignOut: 'http://localhost:3000/',
  responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
};
if (!isLocalhost) {
  console.log("Setting pythonmeta.com redirects")
  oauth.redirectSignIn = "https://www.pythonmeta.com/";
  oauth.redirectSignOut = "https://www.pythonmeta.com/";
}
newExports.oauth = oauth
Amplify.configure(awsExports);
Storage.configure({ level: 'private' });
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "ExecutorApi",
        endpoint: "https://klx8dd2jb0.execute-api.us-east-1.amazonaws.com/dev",
        custom_header: async () => { 
          //return { Authorization : 'token' } 
          // Alternatively, with Cognito User Pools use this:
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});


// ----- Grommet Globals ----- //

const theme = {
  global: {
    colors: {
      brand: '#306998',
      header: '#eeeeee',
    },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
  },
};




// ----- App ----- //

class App extends Component {


    constructor(props) {
        super(props);
        this.state = {
            executorMessage: "",
            executorStdOut: "",
            executorStdErr: "",
            userCode: "",
            showSidebar: false,
            selection: assignments[1],
            autoSaver: null,
        };
    }

    submit() {
        const apiName = 'ExecutorApi';
        const path = '/run';
        const params = { 
          body: {
            puzzleID: this.state.selection.name,
            code: this.state.userCode,
          }
        };
        this.setState({
          executorMessage: "Running",
          executorStdOut: "...",
          executorStdErr: "...",
        });
        API.post(apiName, path, params)
          .then(response => this.setState({
            executorMessage: response.message,
            executorStdOut: response.stdout,
            executorStdErr: response.stderr,
          }))
          .catch(error => {
            console.log(error.response);
          });
    }

    save(puzzleID) {
      Auth.currentAuthenticatedUser()
        .then(user => API.graphql(graphqlOperation(createPuzzleSave, {input: {
            puzzleID: puzzleID,
            code:this.state.userCode,
            owner:user.username,
          }}))
        )
        .then(response => {
          console.log(response);
          store.addNotification({
            title: "Saved",
            message: `${puzzleID}/submission.py saved in the cloud.`,
            type: "warning",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: false
            }
          });
        })
        .catch(err => console.log(err))
    }

    load(puzzleID) {
      Auth.currentAuthenticatedUser()
        .then(user => API.graphql(graphqlOperation(getPuzzleSave, {
            puzzleID: puzzleID,
            owner:user.username,
          }))
        )
        .then(response => {
          if (response.data.getPuzzleSave != null) {
            console.log(`Found Saved Progess for ${puzzleID}`)
            this.setState({userCode:response.data.getPuzzleSave.code});
          }
          else {
            console.log("Loading default submission.py")
            fetch(`/assignments/${puzzleID}/submission.py`)
              .then(response => response.text())
              .then(text => this.setState({userCode: text}))
          }
        })
        .catch(err => console.log(err))
    }

    handleNewSelection(selection) {
      this.save(this.state.selection.name)
      this.setState({selection:selection})
      this.load(selection.name)
    }

    componentDidMount() {
      this.load(this.state.selection.name);
    }

    userCodeChange(newVal) {
      clearTimeout(this.state.autoSaver);
      let autoSaver = setTimeout(() => this.save(this.state.selection.name), 5000);
      this.setState({
        userCode:newVal,
        autoSaver:autoSaver,
      });
    }

    render() {
        return (
            <>
            <ReactNotification />
            <Grommet theme={theme} full>
              <Box fill>
                <AppBar
                  onMenu={() => this.setState({showSidebar:!this.state.showSidebar})}
                  onPlay={() => this.submit()}
                  onUpload={() => this.save(this.state.selection.name)}
                />
                <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                  <SideBar 
                    open={this.state.showSidebar}
                    onSelection={ selection => this.handleNewSelection(selection)}
                  />
                  <Editor
                    assignment={this.state.selection}
                    userCode={this.state.userCode}
                    onUserCodeChange={ newVal => this.userCodeChange(newVal) }
                  />
                  <Output
                    assignment={this.state.selection}
                    executorMessage={this.state.executorMessage}
                    executorStdOut ={this.state.executorStdOut }
                    executorStdErr ={this.state.executorStdErr }
                  />
                </Box>
              </Box>
            </Grommet>
            </>
        );
    }
}

export default App;

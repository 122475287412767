import { withOAuth } from 'aws-amplify-react';
import React, { Component } from 'react';
import { Button } from 'grommet'
import { Google } from 'grommet-icons'

class OAuthButton extends Component {
  render() {
    return (
    <Button icon={<Google />} onClick={this.props.OAuthSignIn} />
    )
  }
}

export default withOAuth(OAuthButton);

import React, { Component } from "react"
import { Box, Collapsible, Button } from "grommet"
import assignments from "../assignment-manifest"

class SideBar extends Component {


    render() {
        let renderedAssignments = []
        assignments.forEach(assignment => {
          renderedAssignments.push(
            <Button 
              label={assignment.name}
              onClick={() => this.props.onSelection(assignment)}
            />
          )
        })
        return (
          <Collapsible direction="horizontal" open={this.props.open}>
            <Box
              flex
              width='20em'
              background='light-2'
              elevation='small'
              align='center'
              justify='center'
            >
              <h4> Puzzles </h4>

              <Box
                direction="column"
              >
                {renderedAssignments}
              </Box>
            </Box>
          </Collapsible>
        );
    }
}

export default SideBar;
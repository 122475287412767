
import React, { Component } from "react";
import { Box, Button, Heading} from 'grommet';
import { Menu, Play, Save, Logout } from 'grommet-icons';
import OAuthButton from './OAuthButton';
import { Auth, Hub} from 'aws-amplify';




class AppBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
          authState: 'loading',
          authData: null,
          authError: null
        }
        this.signOut = this.signOut.bind(this);
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    this.setState({authState: 'signedIn', authData: data.payload.data});
                    break;
                case 'signIn_failure':
                    this.setState({authState: 'signIn', authData: null, authError: data.payload.data});
                    break;
                default:
                    break;
            }
        });
    }

    componentDidMount() {
          Auth.currentAuthenticatedUser().then(user => {
            console.log(user);
            this.setState({authState: 'signedIn'});
          }).catch(e => {
            console.log(e);
            this.setState({authState: 'signIn'});
          });
      }
    signOut() {
        Auth.signOut().then(() => {
            this.setState({authState: 'signIn'});
        }).catch(e => {
            console.log(e);
        });
    }

    render() {
        return (
            <Box
              tag='header'
              direction='row'
              align='center'
              justify='between'
              background='brand'
              pad={{ left: 'medium', right: 'small', vertical: 'small' }}
              elevation='medium'
              style={{ zIndex: '1' }}
            >
                <Heading level='3' margin='none'>Python Metaprogramming</Heading>
                <Button 
                    icon={<Menu />} 
                    onClick={this.props.onMenu} 
                />
                <Button 
                    icon={<Save />} 
                    onClick={this.props.onUpload} 
                />
                <Button 
                    icon={<Play />} 
                    onClick={this.props.onPlay} 
                />
                
                {this.state.authState === 'loading' && (<div>loading...</div>)}
                {this.state.authState === 'signIn' && <OAuthButton/>}
                {this.state.authState === 'signedIn' && <Button 
                icon={<Logout/>}
                onClick={this.signOut}/>
                }
            </Box>
        );
    }
}


export default AppBar;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPuzzleSave = /* GraphQL */ `
  query GetPuzzleSave($owner: String!, $puzzleID: String!) {
    getPuzzleSave(owner: $owner, puzzleID: $puzzleID) {
      puzzleID
      code
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPuzzleSaves = /* GraphQL */ `
  query ListPuzzleSaves(
    $owner: String
    $puzzleID: ModelStringKeyConditionInput
    $filter: ModelPuzzleSaveFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPuzzleSaves(
      owner: $owner
      puzzleID: $puzzleID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        puzzleID
        code
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

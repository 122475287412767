
// This file was autogenerated, do not change. 
// Instead, run scripts/build_assignments.py

const assignments = [
    {
        "name": "00-PrisonersDilemma",
        "files": [
            "submission.py",
            "runner.py",
            "readme.md",
            "example_prisoners.py"
        ]
    },
    {
        "name": "01-DependancyInjection",
        "files": [
            "submission.py",
            "runner.py",
            "readme.md",
            "bomb.py"
        ]
    },
    {
        "name": "02-EsotericPrint",
        "files": [
            "submission.py",
            "runner.py",
            "readme.md"
        ]
    },
    {
        "name": "03-Specializer",
        "files": [
            "submission.py",
            "runner.py",
            "readme.md"
        ]
    },
    {
        "name": "04-FastFibonacci",
        "files": [
            "submission.py",
            "runner.py",
            "assert_called.py",
            "readme.md",
            "fib.py"
        ]
    },
    {
        "name": "05-MetaPrisoner",
        "files": [
            "submission.py",
            "runner.py",
            "readme.md",
            "prisoners.py"
        ]
    },
    {
        "name": "06-RestrictedFunction",
        "files": [
            "submission.py",
            "runner.py",
            "assert_called.py",
            "readme.md"
        ]
    },
    {
        "name": "07-ProblemGenerator",
        "files": [
            "readme.md"
        ]
    }
];

export default assignments;
        
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPuzzleSave = /* GraphQL */ `
  mutation CreatePuzzleSave(
    $input: CreatePuzzleSaveInput!
    $condition: ModelPuzzleSaveConditionInput
  ) {
    createPuzzleSave(input: $input, condition: $condition) {
      puzzleID
      code
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePuzzleSave = /* GraphQL */ `
  mutation UpdatePuzzleSave(
    $input: UpdatePuzzleSaveInput!
    $condition: ModelPuzzleSaveConditionInput
  ) {
    updatePuzzleSave(input: $input, condition: $condition) {
      puzzleID
      code
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePuzzleSave = /* GraphQL */ `
  mutation DeletePuzzleSave(
    $input: DeletePuzzleSaveInput!
    $condition: ModelPuzzleSaveConditionInput
  ) {
    deletePuzzleSave(input: $input, condition: $condition) {
      puzzleID
      code
      owner
      createdAt
      updatedAt
    }
  }
`;
